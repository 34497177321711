.responsiveContainer {
  width: calc(100vw - 258px);
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 60px;
}
@media only screen and (max-width: 960px) {
  .responsiveContainer {
    width: 100vw !important;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
}
