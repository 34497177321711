.button-container {
  display: flex;
  justify-content: flex-end;
}

.btn-clear {
  background-color: #6558f5;
  margin-right: 10px;
  transition: 0.3s;
}

.btn-clear:hover {
  background-color: #6558f5;
  filter: brightness(85%);
}

.btn-next {
  background-color: #1aae9f;
  transition: 0.3s;
}

.btn-next:hover {
  background-color: #1aae9f;
  filter: brightness(85%);
}

.progress-content {
  display: flex;
  justify-content: center;
}

.progress {
  width: 200px;
  margin-top: 40px;
  color: #1aae9f;
}
